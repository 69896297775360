import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Hva er brystryggsmerter?</h1>
      <p>
        Brystryggsmerter er når man har smerter som sitter i mellom skulderbladene i øvre, midtre eller nedre del.
        Smertene kan være lokale i midtre del av brystryggen eller de kan kjennes mer på en av sidene i brystryggen. Om
        man puster dypt inn kan smerten kjennes som en skarp stikkende smerte. Smerten kan også være verkende og man kan
        ha utstrålende smerter i eller fra brystrygg.
      </p>

      <p>
        Brystryggsmerter er en vanlig årsak til at folk oppsøker kiropraktor. Som regel vil brystryggen også være
        involvert i korsrygg eller nakkeplager selv om pasienten ikke nødvendigvis kjenner smerter i brystryggen.
        Leddlåsninger gir ikke alltid symptomer, men er alltid med på å skape kompensatoriske problemer. Brystrygg
        behandles daglig hos Kiropraktor Akutten Stavanger/Sandnes og vi har god erfaring med dette. Hver behandling
        varer fra 20-30 minutt.
      </p>
    </Layout>
  )
}

export default Page
